<template>
    <div class="body">
        <van-list
                v-model="listLoading"
                :finished="listLoadingFinished"
                finished-text="没有更多了"
                @load="onLoad"
        >
            <div class="details_content" v-for="(item,index) in listLoadingList" :key="index" >
                <div class="details_content2"  @click="onTabList(item.id)">
                    <div class="details_content2_top">
                        <div class="details_content2_top_left">
                            <div>商家</div>
<!--                            <img :src="'https://seetop-1257860468.file.myqcloud.com/seetop/uploads/' + item.trademark">-->
                        </div>
                        <div class="details_content2_top_right">{{renderStatus(item.status)}}</div>
                    </div>
                    <div class="details_content2_info">
                        <img class="subscribe_product_img" :src="'https://seetop-1257860468.file.myqcloud.com/seetop/uploads/' + item.trademark"/>
                        <div>
                            <div class="details_content2_word1">
                                <div>预定时间</div>
                                <div>{{item.date}}  {{item.time}}</div>
                            </div>
                            <div class="details_content2_word1">
                                <div>预定人数</div>
                                <div>{{item.number}}人</div>
                            </div>
                            <div class="details_content2_word1">
                                <div>位置要求</div>
                                <div>{{item.room}}</div>
                            </div>
                            <div class="details_content2_word1">
                                <div>联系方式</div>
                                <div>{{item.name}}  {{item.phone}}</div>
                            </div>
                            <div class="details_content2_word1" v-if="item.fail_reason">
                                <div>拒绝原因</div>
                                <div>{{item.fail_reason}}</div>
                            </div>
                            <div class="details_content2_word1" v-if="item.remark">
                                <div>备注</div>
                                <div>{{item.remark}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </van-list>
    </div>
</template>

<script>
    import { globalConfig, listLoading } from '../../utils/mixin'
    import { hrefUrlCommon } from '../../utils/tools'

    export default {
        name: 'Comment',
        mixins: [globalConfig, listLoading],
        data () {
            return {
                list: [],
                page: 1
            }
        },
        methods: {
            hrefUrlCommon,
            loadingInit: function () {
                //  初始化
                this.page = 1
                this.list = []
                this.listLoadingInit(this.apiUrl + 'online/reserve/getReserveList', 'list', 1, {
                })
            },
            onTabList: function (id) {
                this.$router.push('/subscribe/details/' + id)
            },
            renderStatus: function (status) {
              const r = {
                0: '预约中',
                1: '预约成功',
                2: '已使用',
                '-2': '已取消',
                '-1': '已过期',
                '-3': '预约拒绝'
              }
              return r[status]
            }
        },
        created () {
            this.loadingInit()
        }
    }
</script>

<style scoped>
    * {
        box-sizing: border-box;
    }
    .details_content{
        width: 90vw;
        margin: 3vw 5vw 1vw 5vw;
        background: #ffffff;
        border-radius: 2vw;
    }
    .details_content2{
        padding: 0 4vw;
    }
    .details_content2_top{
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 3.8vw;
        font-weight: 600;
        padding:3vw 0;
    }
    .details_content2_top_left{
        display: flex;
        align-items: center;
        font-size: 3.8vw;
        font-weight: 600;
    }
    .details_content2_top_left img{
        width: 1.5vw;
        margin: 0 2vw;
    }
    .details_content2_top_right{
        font-weight: 500;
        font-size: 3.2vw;
        color: #DC9A64;
    }
    .details_content2_info{
        display: flex;
        padding-bottom: 5vw;
    }
    .details_content2_word1{
        display: flex;
        align-items: center;
        font-size: 3.6vw;
        margin: 1vw 0;
    }
    .details_content2_word1 div:nth-of-type(1){
        color: #A1A1A1;
        margin-right: 4vw;
        min-width: 15vw;
    }
    .details_content2_word1 div:nth-of-type(2){
        color: #333333;
    }
    .details_content2_word2{
        display: flex;
        justify-content: space-between;
        font-size: 3.2vw;
    }
    .details_content2_word3{
        padding: 0.8vw 5vw;
        color: #fff;
        background: #DC9A64;
        border-radius: 5vw;
        margin: 0 1.5vw;
        font-weight: 600;
    }
    .subscribe_product_img{
        width: 20vw;
        height: 20vw;
        border-radius: 2vw;
        border:1px solid #ececec;
        margin:0 4vw 0 0;
    }
</style>
